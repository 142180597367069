import React from 'react';

import { Divider, styled, SxProps } from '@mui/material';

export const SectionDivider = ({
  title,
  count,
  sx,
}: {
  title: string;
  count?: number;
  sx?: SxProps;
}) => (
  <>
    <CustomDivider sx={sx} />
    <SectionTitle>
      {title} {count !== undefined ? `(${count})` : null}
    </SectionTitle>
  </>
);

const CustomDivider = styled(Divider)(() => ({
  backgroundColor: 'var(--color-dark-coal)',
  height: '1px',
  width: '100%',
}));

const SectionTitle = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: 'var(--spacer-m)',
  marginBottom: '30px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '50px',
  },
}));
