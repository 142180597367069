import { DataLayer } from '@piwikpro/react-piwik-pro';
import React, { useRef } from 'react';

import { styled } from '@mui/material';

import { Language, Market, PageType, ServiceSegmentType } from '@mewa/types';

import { marketsConfig } from '../../libs/config/markets';
import {
  getLocalizedLink,
  getLocalizedMandatoryLink,
} from '../../libs/config/markets-utils';
import { slugifyByLang } from '../../libs/slugify';
import t from '../../libs/translate';
import * as ctaLabelExperiment from '../a-b-testing/cta-label-experiment';
import { ContactTeaserWrapper } from '../components/contact-teaser/contact-teaser-wrapper';
import Footer, { LinkConfig } from '../components/footer/footer';
import { Header } from '../components/header/header';
import { MinimalHeader } from '../components/header/minimal-header';
import '../components/header/navigation-fragment';
// this import is necessary for loading the graphQL navigation fragments
import { NavigationData } from '../components/header/types';
import { clearBrochures } from '../templates/conversion/persistence';
import { LayoutFlags, LayoutType } from './types';

type MinimalLayoutProps = React.PropsWithChildren<{
  lang: Language;
  langPrefix: string;
  market: Market;
  locale: string;
  navigationData: NavigationData;
  locationKey: string;
  pageType: PageType;
  featureDev?: boolean;
}>;

type FullLayoutProps = MinimalLayoutProps & {
  serviceSegment?: ServiceSegmentType;
  showContactTeaser?: boolean;
  showHeader?: boolean;
  piwikEventNameForHeaderCTAButton?: string;
  locationSearch: string;
};

export const PageLayout: React.FC<
  FullLayoutProps & { layoutFlags: LayoutFlags }
> = ({ layoutFlags, ...props }) => {
  return layoutFlags.layout === LayoutType.Minimal ? (
    <MinimalPageLayout {...props} />
  ) : (
    <FullPageLayoutWithEvidentlyExperiments
      showContactTeaser={!layoutFlags.noContactTeaser}
      showHeader={layoutFlags.layout !== LayoutType.Campaign}
      {...props}
    />
  );
};

/**
 * This layout is used for pages without menu, teasers and footer (like conversion funnels)
 */
export const MinimalPageLayout = ({
  children,
  langPrefix,
  pageType,
}: MinimalLayoutProps) => (
  <>
    <MinimalHeader langPrefix={langPrefix} />
    <Main data-pagefind-meta={`page_type:${pageType}`}>{children}</Main>
  </>
);

const FullPageLayoutWithEvidentlyExperiments: React.FC<FullLayoutProps> = (
  props,
) => {
  const currentLocationKey = useRef(props.locationKey);

  currentLocationKey.current = props.locationKey;

  return <FullPageLayout {...props} />;
};

/**
 * This page layout is used as the basis for all pages on the website. It
 * contains common UI elements like the navigation or metadata like the page
 * title.
 */
const FullPageLayout: React.FC<FullLayoutProps> = ({
  children,
  lang,
  langPrefix,
  market,
  locale,
  navigationData,
  locationKey,
  locationSearch,
  showContactTeaser,
  showHeader,
  serviceSegment,
  pageType,
  featureDev,
}) => {
  const conversionLinks = navigationData.navigationLinks.conversion;
  const translations = navigationData.headerAndFooterTranslations;

  const { ctaLabel } = ctaLabelExperiment.useConversionButtonLabel({
    translations,
  });

  const column1Links: LinkConfig[] = [
    {
      label: ctaLabel,
      target: `${langPrefix}/${conversionLinks.contact.slug}`,
      onClick: () => {
        DataLayer.push({ event: 'cta_contact_footer' });
      },
    },
    {
      label: t(translations, 'footer_download_brochures'),
      target: `${langPrefix}/${conversionLinks.brochuresDownload.slug}`,
      onClick: () => {
        // We don't want to preselect any brochures when using the footer link,
        // so we clear any previous selection
        clearBrochures();
        DataLayer.push({ event: 'cta_brochure_download_footer' });
      },
    },
    {
      label: conversionLinks.cleaningClothsServiceTestPage.name,
      // links to the "cleaning cloths service test" entry page instead of the page with the form
      target: `${langPrefix}/${conversionLinks.cleaningClothsServiceTestPage.slug}`,
      onClick: () => {
        DataLayer.push({ event: 'cta_cloth_service_test_footer' });
      },
    },
  ];

  const footerDownloads =
    navigationData.footer?.downloads.map(({ label, file }) => ({
      label,
      target: file.fields.assetUrl,
      isExternal: true,
    })) ?? [];

  const { imprint, privacyPolicy, privacyPolicySurvey, supplyChainAct } =
    navigationData.navigationLinks;

  const column2Links: LinkConfig[] = [
    {
      label: privacyPolicy.name,
      target: `${langPrefix}/${privacyPolicy.slug}`,
    },
    {
      label: t(translations, 'footer_whistleblower'),
      target: 'https://mewa.integrityline.app', // this website is the same for all locations
      isExternal: true,
    },
    {
      label: privacyPolicySurvey.name,
      target: `${langPrefix}/${privacyPolicySurvey.slug}`,
    },
    ...(supplyChainAct
      ? [
          {
            label: supplyChainAct.name,
            target: `${langPrefix}/${supplyChainAct.slug}`,
          },
        ]
      : []),
    ...footerDownloads,
    {
      label: imprint.name,
      target: `${langPrefix}/${imprint.slug}`,
    },
    {
      label: t(translations, 'footer_sitemap'),
      target: `${langPrefix}/${slugifyByLang('sitemap', lang)}`, // this slug is the same for all locations
    },
  ];

  const column3Links: LinkConfig[] = [
    {
      label: navigationData.navigationLinks.pressOverview.name,
      target: `${langPrefix}/${navigationData.navigationLinks.pressOverview.slug}`,
    },
    {
      label: t(translations, 'footer_career'),
      target: getLocalizedMandatoryLink('careerPortal', market, lang),
      isExternal: true,
      onClick: () => {
        DataLayer.push({ event: 'external_career_footer' });
      },
    },
    {
      label: t(translations, 'footer_customer_portal'),
      target: getLocalizedMandatoryLink('customerPortal', market, lang),
      isExternal: true,
      onClick: () => {
        DataLayer.push({ event: 'external_customerportal_footer' });
      },
    },
  ];

  if (!marketsConfig[market].hideSupplierPortalLink) {
    column3Links.push({
      label: t(translations, 'footer_supplier_portal'),
      target: 'https://partners.mewa-service.com/', // this website is the same for all locations
      isExternal: true,
      onClick: () => {
        DataLayer.push({ event: 'external_supplierportal_footer' });
      },
    });
  }

  // If the market has a blog, put it at position #1
  if (marketsConfig[market].socialMedia.blog === true) {
    // blog is included in this market
    column3Links.unshift({
      label: navigationData.navigationLinks.blog.generalSlug.name,
      target: `${langPrefix}/${navigationData.navigationLinks.blog.generalSlug.slug}`,
    });
  } else if (typeof marketsConfig[market].socialMedia?.blog === 'string') {
    // add an external link to the blog
    column3Links.unshift({
      label: t(translations, 'footer_mewa_blog'),
      target: marketsConfig[market].socialMedia.blog as string,
      isExternal: true,
    });
  }

  const onlineShop = getLocalizedLink('onlineShop', market, lang);

  if (onlineShop) {
    column3Links.push({
      label: t(translations, 'footer_online_shop'),
      target: onlineShop,
      isExternal: true,
      onClick: () => {
        DataLayer.push({ event: 'external_mbwshop_footer' });
      },
    });
  }

  return (
    <>
      {showHeader && (
        <Header
          langPrefix={langPrefix}
          navigationData={navigationData}
          locationKey={locationKey}
          market={market}
          lang={lang}
          locale={locale}
          locationSearch={locationSearch}
          featureDev={featureDev}
          pageType={pageType}
        />
      )}
      <Main data-pagefind-meta={`page_type:${pageType}`}>{children}</Main>

      {showContactTeaser && (
        <ContactTeaserWrapper
          serviceSegment={serviceSegment}
          langPrefix={langPrefix}
          navigationLinks={navigationData.navigationLinks}
          contactTeaserLabels={navigationData.contactTeaserLabels}
        />
      )}

      <Footer
        column1={column1Links}
        column2={column2Links}
        column3={column3Links}
        translations={translations}
        lang={lang}
        market={market}
      />
    </>
  );
};

const Main = styled('main')({
  position: 'relative',
  zIndex: 0,
  marginBottom: 'var(--spacer-xxxl)',
});

export default PageLayout;
