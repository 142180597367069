export const moveElementToBody = (
  elementToMove: HTMLElement,
  parent: HTMLElement,
) => {
  const baseElement = document.querySelector('#gatsby-focus-wrapper');

  if (baseElement) {
    parent.removeChild(elementToMove);
    baseElement.appendChild(elementToMove);
  }
};

export const removeElementFromBody = (
  elementToMove: HTMLElement,
  parent?: HTMLElement | null,
) => {
  const baseElement = document.querySelector('#gatsby-focus-wrapper');

  if (baseElement && baseElement === elementToMove.parentNode) {
    baseElement.removeChild(elementToMove);
  }

  if (parent) {
    parent.appendChild(elementToMove);
  }
};
