import { ComponentClass } from 'react';

import { Button, Grid2 as Grid, LinkTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const TeaserWithImage = ({
  image,
  LinkComponent,
  title,
  linkLabel,
  link,
  isExternal,
  onClick,
  wideTeaser,
  className,
  useStandardTeaserGrid = true,
}: {
  image: React.ReactNode;
  LinkComponent:
    | ComponentClass<any, any> // eslint-disable-line
    | OverridableComponent<LinkTypeMap<any, 'a'>>; // eslint-disable-line
  title: string;
  linkLabel: string;
  link: string;
  isExternal?: boolean;
  onClick?: () => void;
  wideTeaser?: boolean;
  className?: string;
  useStandardTeaserGrid?: boolean;
}) => {
  const getImageLink = () => {
    return isExternal ? (
      <ZoomingAnchor href={link} target="_blank" rel="noreferrer">
        {image}
      </ZoomingAnchor>
    ) : (
      <ZoomingLink to={link}>{image}</ZoomingLink>
    );
  };

  const ZoomingLink = styled(LinkComponent)(zoomingStyles);
  const ZoomingAnchor = styled('a')(zoomingStyles);

  const content = (
    <Grid container>
      <Grid
        size={{
          xs: 12,
          md: wideTeaser ? 8 : 12,
        }}
      >
        <ZoomingWrapper>{getImageLink()}</ZoomingWrapper>
      </Grid>
      <TeaserTextContainer
        size={{ xs: 11, md: wideTeaser ? 4 : 11 }}
        className={wideTeaser ? 'wideTeaser' : ''}
      >
        <TeaserTitle>{title}</TeaserTitle>
        {isExternal ? (
          <Button
            href={link}
            target="_blank"
            rel="noreferrer"
            color="secondary"
            onClick={onClick}
          >
            {linkLabel}
          </Button>
        ) : (
          <Button component={LinkComponent} to={link} color="secondary">
            {linkLabel}
          </Button>
        )}
      </TeaserTextContainer>
    </Grid>
  );

  return useStandardTeaserGrid ? (
    <TeaserGrid
      size={{ xs: 12, md: wideTeaser ? 12 : 6 }}
      className={className}
    >
      {content}
    </TeaserGrid>
  ) : (
    content
  );
};

const zoomingStyles = {
  display: 'block',
  transition: 'transform var(--transition-duration) var(--transition-timing)',
  '&:hover': {
    transform: 'scale(1.05)',
  },
};

const ZoomingWrapper = styled('div')({
  overflow: 'hidden',
});

const TeaserTextContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  maxWidth: '100%',
  marginLeft: 0,

  '&.wideTeaser': {
    [theme.breakpoints.up('md')]: {
      paddingBottom: '56px',
      paddingLeft: '70px',
      maxWidth: '450px',
    },
  },
}));

const TeaserGrid = styled(Grid)(({ theme }) => ({
  marginTop: '35px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '80px',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '150px',
  },
}));

const TeaserTitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h4,
  marginBottom: '16px',
  marginTop: '35px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '28px',
    marginBottom: '10px',
  },
}));
