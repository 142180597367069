import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass, { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { styled } from '@mui/material';

import swiperClasses from '../../styles/swiper-pagination.module.scss';
import {
  SliderButtonNext,
  SliderButtonPrev,
  SliderButtonStyle,
} from '../slider-button/slider-button';

export type MobileSliderProps = {
  slidesPerView?: {
    [width: number]: { slidesPerView: number };
  };
};

const navButtonStyling = {
  styling: {
    fillMode: 'outlined',
    color: 'dark-coal',
  } as SliderButtonStyle,
  hoverStyling: {
    fillMode: 'outlined',
    color: 'light-red',
  } as SliderButtonStyle,
};

export const MobileSlider = ({
  children,
  slidesPerView,
}: React.PropsWithChildren & MobileSliderProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  return (
    <Container>
      <StyledNavPrev
        className={``}
        onClick={() => {
          swiper?.slidePrev();
        }}
        mobileSmall
        {...navButtonStyling}
      />
      <Swiper
        onSwiper={setSwiper}
        className={swiperClasses.swiper}
        modules={[Pagination]}
        loop={true}
        slideToClickedSlide={false}
        allowTouchMove={true}
        breakpoints={slidesPerView}
        slidesPerView={1}
        pagination={{
          type: 'bullets',
          dynamicBullets: false,
          dynamicMainBullets: 1,
          clickable: true,
          renderBullet: (_index, className) =>
            `<div class="${swiperClasses.navigationDot} ${className}"></div>`,
        }}
      >
        {Array.isArray(children)
          ? children.map((child, idx) => (
              <SwiperSlide key={idx}>{child}</SwiperSlide>
            ))
          : null}
      </Swiper>
      <StyledNavNext
        className={``}
        onClick={() => {
          swiper?.slideNext();
        }}
        mobileSmall
        {...navButtonStyling}
      />
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledNavPrev = styled(SliderButtonPrev)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledNavNext = styled(SliderButtonNext)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
