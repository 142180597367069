import React from 'react';

import { Button, Grid2 as Grid, styled } from '@mui/material';

import { ContactPhone, EmailIcon } from '../../../../assets/icons';
import ResponsiveGrid from '../../../layout/grid';

export type ContactTeaserLabelProps = {
  consultationTitle: string;
  consultationSubTitle: string;
  consultationCTALabel: string;
  contactTitle: string;
  contactSubTitle: string;
  openingHours: string;
  phoneNumber: string;
};

type Props = {
  labels: ContactTeaserLabelProps;
  onClickConsultation: () => void;
  onClickHotline: () => void;
  className?: string;
};

export const ContactTeaser = React.memo(
  ({
    labels: {
      consultationTitle,
      consultationSubTitle,
      consultationCTALabel,
      contactTitle,
      contactSubTitle,
      openingHours,
      phoneNumber,
    },
    onClickConsultation,
    onClickHotline,
    className = '',
  }: Props) => {
    const ConsultationContent = ({
      variant,
    }: {
      variant: 'mobile' | 'desktop';
    }) => (
      <ContactBox>
        <IconWrapper>
          <MailIcon />
        </IconWrapper>

        <ContactTitle>{consultationTitle}</ContactTitle>
        <ContactText>{consultationSubTitle}</ContactText>

        {variant === 'mobile' && <LinkArea />}
        {variant === 'desktop' && (
          <Button
            onClick={onClickConsultation}
            variant="contained"
            sx={{
              display: ['none', 'inline-flex'],
              marginTop: '25px',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {consultationCTALabel}
          </Button>
        )}
      </ContactBox>
    );

    const DesktopConsultationBox = styled('div')(({ theme }) => ({
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }));

    const MobileConsultationBox = styled('div')(({ theme }) => ({
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }));

    const ConsultationCard = () => (
      <>
        <DesktopConsultationBox>
          <ConsultationContent variant="desktop" />
        </DesktopConsultationBox>

        <MobileConsultationBox>
          <button
            onClick={onClickConsultation}
            style={{
              font: 'unset',
              border: 'none',
              cursor: 'pointer',
              display: 'block',
              width: '100%',
              padding: 0,
            }}
          >
            <ConsultationContent variant="mobile" />
          </button>
        </MobileConsultationBox>
      </>
    );

    const HotlineCard = () => (
      <ContactBox>
        <IconWrapper>
          <PhoneIcon />
        </IconWrapper>

        <ContactTitle isHotline>{contactTitle}</ContactTitle>
        <ContactText>
          {contactSubTitle}
          <br />
          {openingHours}
        </ContactText>

        <PhoneNumberWrapper>
          <PhoneNumberText href={`tel:${phoneNumber}`} onClick={onClickHotline}>
            {phoneNumber}
          </PhoneNumberText>
        </PhoneNumberWrapper>
      </ContactBox>
    );

    return (
      <Container className={className}>
        <Grid
          offset={{ xs: 0, sm: 1, md: 2 }}
          sx={{ marginBottom: '20px' }}
          size={{
            xs: 12,
            sm: 5,
            md: 4,
          }}
        >
          <HotlineCard />
        </Grid>
        <Grid
          sx={{ marginBottom: '20px' }}
          size={{
            xs: 12,
            sm: 5,
            md: 4,
          }}
        >
          <ConsultationCard />
        </Grid>
      </Container>
    );
  },
);

export default ContactTeaser;

const MailIcon = styled(EmailIcon)(({ theme }) => ({
  color: 'var(--color-light-red)',
  width: '45px',
  height: '45px',

  [theme.breakpoints.up('md')]: {
    width: '60px',
    height: '46px',
  },

  [theme.breakpoints.up('lg')]: {
    width: '66px',
    height: '51px',
  },
}));

const PhoneIcon = styled(ContactPhone)(({ theme }) => ({
  color: 'var(--color-light-red)',
  width: '37px',
  height: '37px',

  [theme.breakpoints.up('md')]: {
    width: '46px',
    height: '46px',
  },

  [theme.breakpoints.up('lg')]: {
    width: '51px',
    height: '51px',
  },
}));

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: 'var(--spacer-xxxl)',
  backgroundColor: 'var(--color-light-grey)',
  paddingTop: '30px',
  paddingBottom: '10px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '46px',
    paddingBottom: '58px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '80px',
    paddingBottom: '100px',
  },
}));

const LinkArea = styled('div')(({ theme }) => ({
  display: 'none',
  border: 'none',
  width: '48px',
  backgroundColor: 'transparent',

  [theme.breakpoints.only('xs')]: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContactBox = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'flex-start',
  backgroundColor: 'white',
  gridTemplateRows: '70px auto auto auto',
  padding: '10px 40px 40px 40px',
  height: '100%',

  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: '70px 1fr',
    gridColumnGap: '10px',
    gridTemplateRows: '1fr',
    justifyItems: 'unset',
    padding: '25px 40px 25px 10px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '10px 50px 50px 50px',
    gridTemplateRows: '90px auto auto auto',
  },
}));

const ContactTitle = styled('p')(
  ({ isHotline = false }: { isHotline?: boolean }) =>
    ({ theme }) => ({
      marginTop: 0,
      textAlign: 'center',
      ...theme.typography.h4,
      color: 'var(--color-dark-coal)',
      marginBottom: '10px',

      [theme.breakpoints.down('sm')]: {
        gridColumn: isHotline ? '2/4' : 'unset',
        display: 'flex',
        margin: 0,
        textAlign: 'start',
        alignItems: 'center',
      },
    }),
);

const ContactText = styled('p')(({ theme }) => ({
  ...theme.typography.body,
  marginBottom: 0,
  marginTop: 0,
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PhoneNumberWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.only('xs')]: {
    gridColumn: '2/4',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
}));

const PhoneNumberText = styled('a')(({ theme }) => ({
  ...theme.typography.h6,
  textAlign: 'center',
  color: 'var(--color-light-red)',
  marginTop: '15px',
  marginBottom: '15px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '0px',
    textAlign: 'start',
    marginBottom: '0px',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    gridRow: '1/3',
    width: '70px',
    height: 'auto',
    alignSelf: 'unset',
    alignItems: 'center',
  },

  [theme.breakpoints.up('md')]: {
    width: '90px',
    height: '90px',
  },

  [theme.breakpoints.up('lg')]: {
    width: '100px',
    height: '100px',
  },
}));
