import { allCollections } from '../plugins/gatsby-source-pim/lib/collection-list';
import { allIndustries } from '../plugins/gatsby-source-pim/lib/industry-list';
import { allServiceSegments, Brochure, ServiceSegmentType } from './types';

export const compareServiceSegments = (
  serviceSegmentA: Brochure | ServiceSegmentType,
  serviceSegmentB: Brochure | ServiceSegmentType,
): number => {
  // brand catalog should be the first
  if (serviceSegmentA === 'brandCatalog') {
    return -1;
  }

  if (serviceSegmentB === 'brandCatalog') {
    return 1;
  }

  const indexOfA = allServiceSegments.indexOf(serviceSegmentA);
  const indexOfB = allServiceSegments.indexOf(serviceSegmentB);

  return indexOfA - indexOfB;
};

export const compareCollections = (
  collectionUuid1: string,
  collectionUuid2: string,
): number => {
  let indexOfA = allCollections.indexOf(collectionUuid1);
  let indexOfB = allCollections.indexOf(collectionUuid2);

  // Collections with index = -1 (not found in list) should be sorted to the end
  indexOfA = indexOfA === -1 ? Number.MAX_SAFE_INTEGER : indexOfA;
  indexOfB = indexOfB === -1 ? Number.MAX_SAFE_INTEGER : indexOfB;

  return indexOfA - indexOfB;
};

export const compareIndustryServiceSegments = (
  serviceSegmentA: ServiceSegmentType,
  serviceSegmentB: ServiceSegmentType,
  industryUuid: string,
) => {
  const indexOfA: number | undefined =
    allIndustries[industryUuid]?.indexOf(serviceSegmentA);
  const indexOfB: number | undefined =
    allIndustries[industryUuid]?.indexOf(serviceSegmentB);

  if (indexOfA === undefined || indexOfB === undefined) {
    return undefined;
  }

  if (indexOfA === -1) {
    return 1;
  }
  if (indexOfB === -1) {
    return -1;
  }

  return indexOfA - indexOfB;
};
