import { useRef, useState } from 'react';

import { Button, Grid2 as Grid, styled } from '@mui/material';

import { ResponsiveGrid } from '../../layout/grid';
import { MarkdownContainer } from '../markdown/markdown-container';

type Props = {
  html: string;
  showMoreLabel: string;
  showLessLabel: string;
};

export const SeoFooter = ({ html, showMoreLabel, showLessLabel }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandButton = useRef<HTMLButtonElement>(null);
  const seoFooterRef = useRef<HTMLElement>(null);

  const toggleExpanded = () => {
    setIsExpanded((isExpanded) => !isExpanded);

    // scroll to seoFooter when we close the section
    if (isExpanded) {
      seoFooterRef.current?.scrollIntoView();
    }

    if (expandButton.current) {
      expandButton.current.blur();
    }
  };

  return (
    <aside ref={seoFooterRef}>
      <ResponsiveGrid
        sx={{ marginTop: ['10px', '40px'], marginBottom: ['40px', '100px'] }}
      >
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 6,
          }}
        >
          <StyledMarkdownContainer html={html} isExpanded={isExpanded} />
          <Button
            variant="text"
            ref={expandButton}
            onClick={() => toggleExpanded()}
          >
            {isExpanded ? showLessLabel : showMoreLabel}
          </Button>
        </Grid>
      </ResponsiveGrid>
    </aside>
  );
};

const StyledMarkdownContainer = styled(MarkdownContainer)(
  ({ isExpanded }: { isExpanded: boolean }) =>
    ({ theme }) => ({
      [theme.breakpoints.up(0)]: {
        marginBottom: 0,
      },

      p: {
        marginBottom: '8px',

        [theme.breakpoints.up('sm')]: {
          marginBottom: '10px',
        },

        [theme.breakpoints.up('md')]: {
          marginBottom: '12px',
        },

        [theme.breakpoints.up('lg')]: {
          marginBottom: '15px',
        },
      },

      'p:first-of-type ~ *': {
        display: isExpanded ? undefined : 'none',
      },

      h3: {
        ...theme.typography.h3,
        margin: '8px 0',

        [theme.breakpoints.up('sm')]: {
          margin: '10px 0',
        },

        [theme.breakpoints.up('md')]: {
          margin: '12px 0',
        },

        [theme.breakpoints.up('lg')]: {
          margin: '15px 0',
        },
      },

      h4: {
        margin: '8px 0',

        [theme.breakpoints.up('sm')]: {
          margin: '10px 0',
        },

        [theme.breakpoints.up('md')]: {
          margin: '12px 0',
        },

        [theme.breakpoints.up('lg')]: {
          margin: '15px 0',
        },
      },
    }),
);

export default SeoFooter;
