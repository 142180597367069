import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button, Grid2 as Grid, styled, Theme } from '@mui/material';

import { getARIATranslations } from '../../../hooks/aria-translations';
import ResponsiveGrid from '../../../layout/grid';
import {
  SliderButtonNext,
  SliderButtonPrev,
  SliderButtonStyle,
} from '../../slider-button/slider-button';
import ScrollAnimator from './scroll-animator';
import {
  columnsForCollectionSlider,
  SliderViewport,
  useSliderWidth,
} from './viewport';

const navStyling: SliderButtonStyle = {
  fillMode: 'outlined-white',
  color: 'dark-coal',
};
const navHoverStyling: SliderButtonStyle = {
  fillMode: 'outlined-white',
  color: 'light-red',
};

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type SliderProps<Slide> = {
  title?: string;
  subtitle?: string;
  headingComponent?: HeadingType;
  link?: {
    label: string;
    props: object;
  };
  slides: Slide[];
  viewport: SliderViewport;
  renderSlide: (s: Slide, idx: number) => React.ReactNode;
  emotion?: React.ReactNode;
  withCardSpacing?: boolean;
  getColumnsCount?: (vp: SliderViewport) => number;
  controlsOffset?: string;
  centered?: boolean;
  columnsCount?: number;
};

const EMOTION_COLS = 2;

/**
 * The FixedColumnSlider is based on a fixed width column layout. Each slide is
 * one column wide (except the emotion image, its 2 columns wide) and the number
 * of columns are determined based on the `viewport` prop.
 *
 * Instead of using `transform` to slide the elements left and right, this
 * module uses a regular div container with `overflowX: scroll`. This allows
 * the user to use a trackpad on desktop and makes the scroll interaction on
 * mobile a lot smoother.
 */
export const FixedColumnSlider = <Slide extends object>({
  title,
  headingComponent = 'h3',
  subtitle = '',
  link,
  slides,
  viewport,
  renderSlide,
  emotion,
  withCardSpacing,
  getColumnsCount,
  controlsOffset,
  centered = false,
  columnsCount,
}: SliderProps<Slide>) => {
  const [activeColumnIndex, setActiveColumnIndex] = useState(0);

  const [isClient, setIsClient] = useState(false);
  const maxIndex = useRef<number>(0);
  const collectionSliderColumns = useSliderWidth(columnsForCollectionSlider);

  // The `activeIndex` state is updated via scroll events or by clicking the
  // navigation buttons. We only want to animate the state change when clicking
  // the buttons. Setting this flag to false before calling `setActiveIndex`
  // won't animate the state change.
  const shouldAnimateStateUpdate = useRef(true);

  const container = useRef<HTMLDivElement | null>(null);
  const scroller = useRef<HTMLDivElement | null>(null);
  const didMount = useRef(false);

  const scrollAnimator = useRef(new ScrollAnimator(scroller));

  const hasEmotion = !!emotion;

  const columns = getColumnsCount
    ? getColumnsCount(viewport)
    : (columnsCount ?? collectionSliderColumns);

  const slidesCountWithEmotion =
    hasEmotion && viewport !== 'sm'
      ? slides.length + EMOTION_COLS
      : slides.length;

  useEffect(() => {
    // `columns` can be a a decimal value so we floor it to calculate the max index
    maxIndex.current =
      slidesCountWithEmotion - Math.floor(columns) >= 0
        ? slidesCountWithEmotion - Math.floor(columns)
        : 0;
  }, [slidesCountWithEmotion, columns]);

  const onPrev = useCallback(() => {
    shouldAnimateStateUpdate.current = true;
    setActiveColumnIndex((old) => {
      let newI = hasEmotion && old === EMOTION_COLS ? 0 : old - 1;

      if (newI < 0) {
        newI = 0;
      }

      return newI;
    });
  }, [setActiveColumnIndex, hasEmotion]);

  const onNext = useCallback(() => {
    shouldAnimateStateUpdate.current = true;
    setActiveColumnIndex((old) => {
      let newI = hasEmotion && old < EMOTION_COLS ? EMOTION_COLS : old + 1;
      if (newI > maxIndex.current) {
        newI = maxIndex.current;
      }

      return newI;
    });
  }, [setActiveColumnIndex, maxIndex, hasEmotion]);

  // Handle the scroll event of the slider to synchronize the `activeColumnIndex`
  // to the current scroll position.
  const onSliderScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      // calculate index based on scroll position
      const scrollX = e.currentTarget.scrollLeft;
      const slidePerc = 1 / columns;

      // Retrieve the current value for the CSS variable `--margin` as it might
      // change depending on the viewport size
      //
      // Based on initial testing (desktop Chrome & mobile Safari) calling
      // `getComputedStyle` on every scroll event is not a problem. Takes about
      // 0.01ms on mobile Safari. If other browsers are too slow, we could
      // cache the `--margin` value and update it after a window resize.
      const gridGutterWidthPx = getCurrentLayoutMargin();

      const contentWidth = window.innerWidth - gridGutterWidthPx * 2;
      const slideWidthPx = contentWidth * slidePerc;

      let scrollIdx = Math.round(scrollX / slideWidthPx);
      if (scrollIdx > maxIndex.current) {
        scrollIdx = maxIndex.current;
      }

      if (scrollIdx !== activeColumnIndex) {
        // The user is already scrolling, so we don't want to trigger the
        // scroll animation.
        shouldAnimateStateUpdate.current = false;
        setActiveColumnIndex(scrollIdx);
      }
    },
    [columns, activeColumnIndex, setActiveColumnIndex, maxIndex],
  );

  // Due to some rehydration issues of gatsby/react, the `style.width` prop
  // is not updated properly when the component is rendered on the
  // client for the first time.
  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
      if (container.current) {
        const widthStyle = getContainerWidth(
          slidesCountWithEmotion,
          columns,
          withCardSpacing,
        );
        container.current.style.width = widthStyle;
      }
    }
  }, [isClient, slidesCountWithEmotion, columns, withCardSpacing]);

  useEffect(() => {
    const animator = scrollAnimator.current;

    // Unmount handler to cleanup the animation frame if one is still pending
    return () => {
      animator.cancel();
    };
  }, []);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.scrollLeft = 0;
    }
  }, [slides]);

  // Effect which triggers the scroll animation when the `activeColumnIndex`
  // changes after a click on the navigation buttons.
  useEffect(() => {
    if (
      scroller.current &&
      container.current &&
      didMount.current &&
      shouldAnimateStateUpdate.current
    ) {
      const gridMarginPx = getCurrentLayoutMargin();
      const gridGutterPx = getCurrentLayoutGutter();

      const containerWidth = container.current.getBoundingClientRect().width;

      const colCount = slides.length + (hasEmotion ? EMOTION_COLS : 0);

      const containerExtraSpacing = withCardSpacing ? gridGutterPx : 0;

      // First we remove the container padding (2 * grid margin). If the card
      // spacing is active we need to add back that spacing to the container
      // width. The resulting value is then divided by the column count to get
      // the size of a single column.
      const itemWidth =
        (containerWidth - gridMarginPx * 2 + containerExtraSpacing) / colCount;

      const newX = itemWidth * activeColumnIndex;

      scrollAnimator.current.animateTo(newX);
    }
    didMount.current = true;
  }, [activeColumnIndex, slides, hasEmotion, maxIndex, withCardSpacing]);

  const width = getContainerWidth(
    slidesCountWithEmotion,
    columns,
    withCardSpacing,
  );

  const allSlidesInView =
    activeColumnIndex === 0 && activeColumnIndex >= maxIndex.current;

  const cachedSlides = useMemo(
    () => slides.map((s, idx) => renderSlide(s, idx)),
    [slides, renderSlide],
  );

  const labels = getARIATranslations();

  return (
    <>
      <ResponsiveGrid>
        {emotion && (
          <MobileEmotionImageGrid size={{ xs: 12 }}>
            {emotion}
          </MobileEmotionImageGrid>
        )}
        {title && (
          <Grid size={12}>
            <SliderHeading reducedMobileMargin={!!withCardSpacing}>
              <WrapperHeading headingComponent={headingComponent}>
                <CollectionName>{title}</CollectionName>

                {subtitle.trim().length > 0 && (
                  <>
                    <span style={{ display: 'none' }}>-</span>
                    <CollectionClaim>{subtitle}</CollectionClaim>
                  </>
                )}
              </WrapperHeading>

              {link && (
                <DesktopLink variant="text" {...link.props}>
                  {link.label}
                </DesktopLink>
              )}
            </SliderHeading>
          </Grid>
        )}
      </ResponsiveGrid>
      <Slider>
        <ScrollerWrapper>
          <Scroller
            onScroll={onSliderScroll}
            ref={scroller}
            centered={allSlidesInView && centered}
          >
            <SliderContent
              ref={container}
              withCardSpacing={withCardSpacing}
              style={{
                // initially hide the slider content to prevent a column change
                // after the initial server side rendering
                opacity: isClient ? 1 : 0,
                width,
              }}
            >
              {emotion && (
                <EmotionSlide>
                  <EmotionSlideImageWrapper>{emotion}</EmotionSlideImageWrapper>
                </EmotionSlide>
              )}
              {cachedSlides}
            </SliderContent>
          </Scroller>
        </ScrollerWrapper>
        <PrevButton
          onClick={onPrev}
          styling={navStyling}
          hoverStyling={navHoverStyling}
          disabled={activeColumnIndex === 0}
          style={controlsOffset ? { marginTop: controlsOffset } : undefined}
          ariaLabel={labels.label_back}
        />
        <NextButton
          onClick={onNext}
          styling={navStyling}
          hoverStyling={navHoverStyling}
          disabled={activeColumnIndex >= maxIndex.current}
          style={controlsOffset ? { marginTop: controlsOffset } : undefined}
          ariaLabel={labels.label_next}
        />
      </Slider>
      <ResponsiveGrid>
        <Grid size={12}>
          {link && (
            <MobileLink variant="text" {...link.props}>
              {link.label}
            </MobileLink>
          )}
        </Grid>
      </ResponsiveGrid>
    </>
  );
};

const getContainerWidth = (
  slidesCountWithEmotion: number,
  columns: number,
  withCardSpacing?: boolean,
) => {
  const slidePerc = 1 / columns;

  // The width calculation assumes that the slider's parent does fill the full
  // width of the window without any padding/margin on the sides. The first and
  // last slide of the slider will add the `--margin` to make sure the slider
  // is aligned with the default grid.
  let width = '100%';
  const gridMarginPx = getCurrentLayoutMargin();
  const gridGutter = getCurrentLayoutGutter();

  if (slidesCountWithEmotion > columns) {
    // Because 100% width includes the margins, we need to remove the extra margins
    // when the width is > 100%.
    const remainingCols = slidesCountWithEmotion - columns;
    const removePaddingPx = remainingCols * slidePerc * (gridMarginPx * 2 + 1);

    const widthPerc = 100 + slidePerc * 100 * remainingCols;

    const spacingToAdd = withCardSpacing
      ? remainingCols * gridGutter * slidePerc
      : 0;

    width = `calc(${widthPerc}% - ${removePaddingPx}px + ${spacingToAdd}px)`;
  } else if (slidesCountWithEmotion < columns) {
    const winWidth = typeof window !== 'undefined' ? window.innerWidth : 1024;
    const contentWidth = winWidth - gridMarginPx * 2;
    const slideWidth = contentWidth * slidePerc;
    const colWidthPercent = slideWidth / winWidth;
    const colDiff = columns - slidesCountWithEmotion;
    const spacingToRemove = withCardSpacing
      ? colDiff * gridGutter * slidePerc
      : 0;

    width = `calc(${colWidthPercent * 100 * slidesCountWithEmotion}% + ${
      gridMarginPx * 2 - spacingToRemove
    }px)`;
  }

  return width;
};

/**
 * Get the current value of the CSS variable `--margin`.
 */
const getCurrentLayoutMargin = (): number => getCSSVariableInPx('--margin', 60);

/**
 * Get the current value of the CSS variable `--gutter`.
 */
const getCurrentLayoutGutter = (): number => getCSSVariableInPx('--gutter', 60);

/**
 * Get the current value for a given CSS variable
 */
const getCSSVariableInPx = (varName: string, defaultVal: number): number =>
  typeof window !== 'undefined'
    ? parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(varName),
      )
    : defaultVal;

const SliderHeading = styled('div', {
  shouldForwardProp: (prop) => prop !== 'reducedMobileMargin',
})<{ reducedMobileMargin: boolean }>(({ reducedMobileMargin, theme }) => ({
  marginBottom: reducedMobileMargin ? '15px' : '33px',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const WrapperHeading = ({
  headingComponent,
  children,
}: PropsWithChildren & { headingComponent: HeadingType }) => {
  const Comp = styled(headingComponent)(({ theme }) => ({
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
  }));

  return <Comp>{children}</Comp>;
};

const CollectionName = styled('span')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightMedium,
  margin: '0 18px 0 0',
  [theme.breakpoints.down('md')]: {
    marginBottom: '3px',
  },
}));

const CollectionClaim = styled('span')(({ theme }) => ({
  ...theme.typography.body,
  fontWeight: theme.typography.fontWeightRegular,
  margin: '0 18px 5px 0',
  flexGrow: 1,
}));

const DesktopLink = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  alignSelf: 'flex-end',
  top: '-7px',
  marginBottom: '-10px',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileLink = styled(Button)(({ theme }) => ({
  marginTop: '24px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Slider = styled('div')({
  position: 'relative',
  zIndex: 0,
});

const ScrollerWrapper = styled('div')({
  position: 'relative',
  overflowY: 'hidden',
  width: '100%',
});

const Scroller = styled('div')(({ centered }: { centered: boolean }) => ({
  overflowX: 'scroll',
  overflowY: 'hidden',
  marginBottom: -30,

  ...(centered
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : {}),
}));

const SliderContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withCardSpacing',
})<{ withCardSpacing?: boolean }>(({ withCardSpacing }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: '1fr',
  backgroundColor: 'white',
  paddingBottom: '30px',
  paddingRight: withCardSpacing
    ? 'calc(var(--margin) - var(--gutter))'
    : 'var(--margin)',
  paddingLeft: 'var(--margin)',
  transition: 'opacity var(--transition-duration) var(--transition-timing)',

  '> div': {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    marginRight: withCardSpacing ? 'var(--gutter)' : '-1px',
    border: withCardSpacing ? 0 : '1px solid var(--color-medium-light-grey)',
    transition:
      'all var(--transition-duration) var(--transition-timing) !important',

    webkitTransform: 'translateZ(0)',
    webkitBackfaceBisibility: 'hidden',

    '&:last-of-type': {
      marginRight: withCardSpacing ? undefined : '0 !important',
    },
  },
}));

const EmotionSlide = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: '0px !important',
  gridColumn: `span ${EMOTION_COLS}`,
  border: '0 !important',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MobileEmotionImageGrid = styled(Grid)(({ theme }) => ({
  marginBottom: '24px',
  '> *': {
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const EmotionSlideImageWrapper = styled('div')({
  marginRight: 'var(--gutter)',
  width: '100%',
  position: 'relative',

  '> *': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',

    img: {
      objectFit: 'cover',
      objectPosition: 'top',
    },
  },
});

const navButtonBaseStyle = (theme: Theme): object => ({
  position: 'absolute',
  top: '50%',
  transition: 'opacity var(--transition-duration) var(--transition-timing)',
  '&:disabled': {
    opacity: 0,
    cursor: 'default',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

const PrevButton = styled(SliderButtonPrev)(({ theme }) => ({
  ...navButtonBaseStyle(theme),
  left: 'var(--margin)',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
}));

const NextButton = styled(SliderButtonNext)(({ theme }) => ({
  ...navButtonBaseStyle(theme),
  right: 'var(--margin)',
  transform: 'translate(50%, -50%)',
  zIndex: 1,
}));
