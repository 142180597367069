import React from 'react';

import { Button, Card, cardClasses, styled } from '@mui/material';

import { DataGridList } from '../../data-grid-list/data-grid-list';
import CardSlide from '../core/card-slide';

export type RelatedProductCardProps = React.ComponentProps<typeof Card> & {
  className?: string;
  image: React.ReactElement;
  title: string;
  keyFacts: string[];
  productLink: {
    url: string;
    label: string;
    LinkComponent: React.ElementType;
  };
};

export const ProductCardKeyFacts = React.memo(
  ({
    image,
    title,
    keyFacts,
    productLink: { url, label, LinkComponent },
    className = '',
  }: RelatedProductCardProps) => {
    return (
      <CardSlide className={className}>
        <ProductCard variant="outlined">
          <LinkComponent to={url}>
            <ClickArea>
              <ImageWrapper>{image}</ImageWrapper>
              <ProductNameContainer>
                <ProductName>{title}</ProductName>
              </ProductNameContainer>
            </ClickArea>
          </LinkComponent>
          <KeyFactsContainer>
            <KeyFacts items={keyFacts} />
          </KeyFactsContainer>
          <ButtonContainer>
            <Button variant={'text'} component={LinkComponent} to={url}>
              {label}
            </Button>
          </ButtonContainer>
        </ProductCard>
      </CardSlide>
    );
  },
);

const KeyFactsContainer = styled('div')(({ theme }) => ({
  paddingLeft: '40px',
  paddingRight: '44px',
  width: '100%',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  justifySelf: 'center',
  alignSelf: 'flex-end',
  marginBottom: '40px',
  marginTop: '20px',
}));

const ClickArea = styled('div')({
  display: 'grid',
  marginTop: '50px',

  '&:hover > :first-of-type': {
    opacity: '0.7',
  },
});

const ImageWrapper = styled('div')({
  aspectRatio: '1/1',
  width: '60%',
  justifySelf: 'center',
  transition: 'opacity var(--transition-duration) var(--transition-timing)',
});

const ProductNameContainer = styled('div')({
  paddingLeft: '40px',
  paddingRight: '20px',
  width: '100%',
});

const ProductName = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  color: 'var(--color-dark-coal)',
  marginBottom: '20px',
  marginTop: '35px',
}));

const KeyFacts = styled(DataGridList)(({ theme }) => ({
  ...theme.typography.body,

  'li:not(:last-of-type)': {
    marginBottom: '6px',

    [theme.breakpoints.up('md')]: {
      paddingBottom: '10px',
    },
  },
}));

const ProductCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  // aspectRatio: '4/7',
  padding: 0,
  display: 'grid',
  gridTemplateRows: 'min-content auto 100px',
  justifyContent: 'stretch',

  [theme.breakpoints.up('sm')]: {
    gridTemplateRows: 'min-content auto 110px',
    // aspectRatio: '3/5',
  },

  [theme.breakpoints.up('md')]: {
    // aspectRatio: '4/7',
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateRows: 'min-content auto 120px',
  },

  [`&.${cardClasses.root}`]: {
    border: 'none',
  },
}));
