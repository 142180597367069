import React from 'react';

import { Grid2 as Grid, styled, SxProps, Theme, useTheme } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import {
  ConversionButton,
  ConversionButtonProps,
} from '../conversion-button/conversion-button';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type PageHeadlineProps<T extends React.ElementType> = {
  title: JSX.Element;
  component?: HeadingType;
  subtitle?: JSX.Element | null;
  sx?: SxProps<Theme>;
  cta?: ConversionButtonProps<T> & {
    label: string;
  };
  className?: string;
};

export const PageHeadline = <T extends React.ElementType>({
  title,
  component = 'h1',
  subtitle,
  sx = [],
  cta,
  className,
}: PageHeadlineProps<T>) => {
  const theme = useTheme();

  const CustomHeading = styled(component)(({ theme }) => ({
    ...theme.typography.h1,
    margin: 0,
  }));

  return (
    <ResponsiveGrid
      className={className}
      sx={[
        {
          marginTop: '176px',
          marginBottom: '200px',

          [theme.breakpoints.down('sm')]: {
            marginTop: '64px',
            marginBottom: '35px',
          },
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Grid size={11}>
        <CustomHeading>{title}</CustomHeading>

        {subtitle && <Subline>{subtitle}</Subline>}
      </Grid>
      {cta && (
        <Grid size={12}>
          <ConversionButton {...cta} variant="contained">
            {cta.label}
          </ConversionButton>
        </Grid>
      )}
    </ResponsiveGrid>
  );
};

const Subline = styled('h2')(({ theme }) => ({
  ...theme.typography.h1,
  margin: 0,
}));
