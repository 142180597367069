import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass, { EffectFade, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import { Button, ButtonTypeMap, Grid2 as Grid, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../../layout/grid';
import styleVariables from '../../../styles/export-variables.module.scss';
import swiperClasses from '../../../styles/swiper-pagination.module.scss';
import { useAutoStartAnimation } from './auto-start-animation';

type SlideData<T extends React.ElementType> = {
  industryName: string;
  link: {
    props: OverrideProps<ButtonTypeMap, T>;
  };
  imageAltText?: string | null;
  image: React.ReactElement;
};

type Props<T extends React.ElementType> = {
  slides: SlideData<T>[];
  linkComponent: T;
};

export const RolodexStage = <T extends React.ElementType>({
  slides,
  linkComponent,
}: Props<T>) => {
  const [imageCaptionSwiper, setImageCaptionSwiper] =
    useState<SwiperClass | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const slideToIndex = useCallback(
    (index: number) => {
      if (imageCaptionSwiper) {
        imageCaptionSwiper.slideTo(index);
      }
    },
    [imageCaptionSwiper],
  );

  const slideNext = useCallback(() => {
    slideToIndex(((imageCaptionSwiper?.realIndex ?? 0) + 1) % slides.length);
  }, [imageCaptionSwiper, slides.length, slideToIndex]);

  const { pauseAnimation } = useAutoStartAnimation(slideNext);

  const onSlideChange = useCallback(
    (s: SwiperClass) => {
      setActiveItemIndex(s.realIndex);
    },
    [setActiveItemIndex],
  );

  return (
    <HeightContainer>
      <TextContainer>
        <ResponsiveGrid>
          <CaptionContainer size={12}>
            {slides.map((slide, idx) => (
              <DefaultCaptionText
                onClick={() => {
                  pauseAnimation();
                  slideToIndex(idx);
                }}
                key={idx}
                className={idx === activeItemIndex ? 'highlight' : ''} //generateItemClassNames(idx)}
              >
                {slide.industryName}
              </DefaultCaptionText>
            ))}
            <StyledButton
              variant={'contained'}
              LinkComponent={linkComponent}
              {...slides[activeItemIndex].link.props}
            >
              {slides[activeItemIndex].industryName}
            </StyledButton>
          </CaptionContainer>
        </ResponsiveGrid>
      </TextContainer>
      <SwiperContainer>
        <Swiper
          style={{ height: '100%' }}
          modules={[EffectFade, Pagination]}
          effect="fade"
          speed={parseInt(styleVariables.transitionDuration)}
          normalizeSlideIndex
          allowSlideNext
          allowSlidePrev
          onSwiper={setImageCaptionSwiper}
          allowTouchMove={true}
          onSlideChange={onSlideChange}
          className={swiperClasses.swiper}
          pagination={{
            type: 'bullets',
            dynamicBullets: false,
            dynamicMainBullets: 1,
            clickable: true,
            renderBullet: (index, className) =>
              `<button 
                class="${swiperClasses.navigationDot} ${className}" 
                aria-label="navigate to slide ${index + 1}" 
                onclick="window.dispatchEvent(new Event('pauseAnimation'))"
              ></button>`,
          }}
        >
          {slides.map((slide, idx) => (
            <SwiperSlide key={idx} style={{ position: 'relative' }}>
              <Slide>{slide.image}</Slide>
              <GradientContainer />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </HeightContainer>
  );
};

const HeightContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '550px',
  display: 'flex',

  [theme.breakpoints.up('sm')]: {
    height: '574px',
  },

  [theme.breakpoints.up('md')]: {
    height: '660px',
  },

  [theme.breakpoints.up('lg')]: {
    height: '750px',
  },
}));

const SwiperContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: '0 0 0 0',
  zIndex: 0,
}));

const TextContainer = styled('div')(({ theme }) => ({
  width: '100%',
  alignSelf: 'flex-end',
  marginBottom: 'calc(var(--spacer-l) + 15px)',
  zIndex: 1,
}));

const CaptionContainer = styled(Grid)(({ theme }) => ({
  padding: 'var(--spacer-l) 0 var(--spacer-xl)',
}));

const DefaultCaptionText = styled('button')(({ theme }) => ({
  ...theme.typography.body,
  cursor: 'pointer',
  margin: 0,
  display: 'block',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 0,
  textAlign: 'left',
  marginBottom: 'var(--spacer-s)',
  color: 'var(--color-white)',
  opacity: 0.5,
  transition: 'all var(--transition-duration) ease-in-out',
  padding: 0,

  '&.highlight': {
    opacity: 1,
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightLight,
    marginTop: 'var(--spacer-m)',
    marginBottom: 'var(--spacer-m)',

    '&:first-of-type': {
      marginTop: 0,
    },
  },

  '.highlight + &, &:has(+ .highlight)': {
    opacity: 0.75,
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightRegular,
  },

  '.highlight + button + &, &:has(+ button + .highlight)': {
    opacity: 0.5,
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightRegular,
  },

  '.highlight + button + button + &, &:has(+ button + button + .highlight)': {
    opacity: 0.5,
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightRegular,
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

const Slide = styled('div')(({ theme }) => ({
  height: '100%',

  'img, .gatsby-image-wrapper': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',

    [theme.breakpoints.down('sm')]: {
      objectPosition: 'top',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: 'var(--spacer-l)',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const GradientContainer = styled('div')(({ theme }) => ({
  background:
    'linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)',
  inset: '0 0 0 0',
  position: 'absolute',

  [theme.breakpoints.up('sm')]: {
    background:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 70%)',
  },
}));
