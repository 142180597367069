import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material';

import styles from '../../styles/export-variables.module.scss';

export type ModalProps = {
  show?: boolean;
  onClose?: () => void;
};

export const Modal = React.memo(
  React.forwardRef<HTMLDivElement, React.PropsWithChildren<ModalProps>>(
    ({ show = false, children, onClose }, ref) => {
      const [hideClass, setHideClass] = useState('');

      const closeModal = () => {
        setHideClass('hide');
      };

      useEffect(() => {
        if (hideClass === 'hide') {
          setTimeout(() => {
            setHideClass('');
            if (onClose) {
              onClose();
            }
          }, styles.transitionDuration);
        }
      }, [hideClass, onClose]);

      return show ? (
        <div ref={ref}>
          <Overlay
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                closeModal();
              }
            }}
            className={hideClass}
          >
            <Container className={hideClass}>
              <IconContainer>
                <Icon onClick={closeModal}>
                  <CloseIcon />
                </Icon>
              </IconContainer>
              {children}
            </Container>
          </Overlay>
        </div>
      ) : null;
    },
  ),
);

const CloseIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5858 24.1421L16.2218 30.5061L17.636 31.9203L24 25.5563L30.364 31.9203L31.7782 30.5061L25.4142 24.1421L31.7782 17.7782L30.364 16.364L24 22.7279L17.636 16.364L16.2218 17.7782L22.5858 24.1421Z"
      fill="currentColor"
    />
  </svg>
);

const Overlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: '0 0 0 0',
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(75px)',
  transition:
    'opacity var(--transition-duration) linear, backdrop-filter var(--transition-duration)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '40px 0 40px',
  animation: 'var(--transition-duration) ease-out show',
  overflowY: 'scroll',
  zIndex: 1,

  [theme.breakpoints.up('sm')]: {
    padding: '150px 0 150px',
  },

  '@keyframes show': {
    from: { opacity: 0, backdropFilter: 'none' },
    to: { opacity: 1, backdropFilter: 'blur(75px)' },
  },

  '&.hide': {
    transition:
      'opacity var(--transition-duration) ease-in, backdrop-filter var(--transition-duration) ease-in',
    opacity: 0,
    backdropFilter: 'none',
  },
}));

const Container = styled('div')(({ theme }) => ({
  width: '335px',
  backgroundColor: 'white',
  padding: 'var(--spacer-m) var(--spacer-l) var(--spacer-l)',
  animation: 'var(--transition-duration) ease-out show-content',

  '@keyframes show-content': {
    from: { transform: 'translateY(50%)' },
    to: { transform: 'translateY(0)' },
  },

  [theme.breakpoints.up('sm')]: {
    width: '673px',
  },

  [theme.breakpoints.up('md')]: {
    width: '695px',
  },

  [theme.breakpoints.up('lg')]: {
    width: '920px',
  },

  '&.hide': {
    transition: 'transform var(--transition-duration) ease-in',
    transform: 'translateY(50%)',
  },
}));

const IconContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginBottom: 'var(--spacer-m)',
});

const Icon = styled('button')({
  color: 'var(--color-dark-coal)',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',

  '&:hover': {
    color: 'var(--color-light-red)',
  },
});
