import { useCallback, useEffect, useMemo, useState } from 'react';

import SwiperClass from 'swiper';

import { GalleryLightBox } from './gallery-light-box';
import { MediaGalleryData } from './types';

export const useGalleryLightBox = (
  items: MediaGalleryData[],
  swiper: SwiperClass | null,
) => {
  const [expandedIndex, setExpanded] = useState<number | null>(null);

  const expand = (index: number | null) => {
    setExpanded(index);
  };

  const showPrevious = useCallback(() => {
    setExpanded((old) => (old !== null && old > 0 ? old - 1 : old));
  }, []);

  const showNext = useCallback(() => {
    setExpanded((old) =>
      old !== null && old < items.length - 1 ? old + 1 : old,
    );
  }, [items]);

  const close = useCallback(() => {
    setExpanded(null);
  }, [setExpanded]);

  const render = useMemo(
    () => (
      <GalleryLightBox
        items={items}
        expandedIndex={expandedIndex}
        onClose={close}
        onNext={showNext}
        onPrevious={showPrevious}
      />
    ),
    [items, expandedIndex, close, showNext, showPrevious],
  );

  useEffect(() => {
    if (expandedIndex !== null) {
      swiper?.slideTo(expandedIndex / 4);
    }
  }, [expandedIndex, swiper]);

  return {
    expandedIndex,
    showNext,
    showPrevious,
    expand,
    close,
    galleryLightBox: render,
  };
};
