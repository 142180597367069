import { Components, Theme } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}

const colorTransition = (color: 'background-color' | 'color') =>
  `all 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, ${color} var(--transition-duration) ease-in-out`;

const button = (theme: Theme): Components<Theme>['MuiButton'] => ({
  defaultProps: {
    size: 'large',
    disableElevation: true,
    disableRipple: true,
  },
  variants: [],
  styleOverrides: {
    root: {
      '&': {
        ...theme.typography.body,
        fontWeight: theme.typography.fontWeightMedium,
      },

      borderRadius: 0,
      boxShadow: 'none',
      textTransform: 'none',
      position: 'relative',
      // disabling min-width because we have short button texts
      minWidth: '0',

      '&:hover, &:focus': {
        boxShadow: 'none',

        [`&.${buttonClasses.outlined}`]: {
          [`&.${buttonClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.primary.main,
            color: 'var(--color-white)',
          },

          [`&.${buttonClasses.colorSecondary}`]: {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
          },

          [`&.MuiButton-outlinedInverted`]: {
            backgroundColor: 'var(--color-white)',
            color: theme.palette.primary.main,
          },
        },

        [`&.${buttonClasses.contained}`]: {
          [`&.${buttonClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: 'var(--color-white)',
          },

          [`&.${buttonClasses.colorSecondary}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: 'var(--color-white)',
          },

          '&.MuiButton-containedInverted': {
            color: theme.palette.primary.dark,
          },
        },
      },

      [`&.${buttonClasses.colorPrimary}`]: {
        borderColor: theme.palette.primary.main,
      },

      [`&.${buttonClasses.colorSecondary}`]: {
        borderColor: theme.palette.secondary.main,
      },

      '&.MuiButton-outlinedInverted': {
        borderColor: 'var(--color-white)',
      },

      '--padding-top': '11px',
      '--padding-horizontal': '20px',
      '--padding-bottom': '13px',

      [theme.breakpoints.up('sm')]: {
        '--padding-horizontal-sm': '25px',
        '--padding-top': '13px',
        '--padding-bottom': '15px',
      },

      [theme.breakpoints.up('md')]: {
        '--padding-horizontal': '27px',
        '--padding-top': '14px',
        '--padding-bottom': '16px',
      },

      [theme.breakpoints.up('lg')]: {
        '--padding-horizontal': '30px',
        '--padding-top': '16px',
        '--padding-bottom': '18px',
      },

      [`&.${buttonClasses.contained}`]: {
        paddingTop: 'var(--padding-top)',
        paddingRight: 'var(--padding-horizontal)',
        paddingBottom: 'var(--padding-bottom)',
        paddingLeft: 'var(--padding-horizontal)',
      },

      [`&.${buttonClasses.outlined}`]: {
        borderWidth: 2,
        paddingTop: 'calc(var(--padding-top) - 2px)',
        paddingRight: 'var(--padding-horizontal)',
        paddingBottom: 'calc(var(--padding-bottom) - 2px)',
        paddingLeft: 'var(--padding-horizontal)',
      },

      [`&.${buttonClasses.contained}, &.${buttonClasses.outlined}`]: {
        [`.${buttonClasses.startIcon}`]: {
          margin: '-11px 6px -13px -8px',

          svg: { width: '36px', height: '36px' },

          [theme.breakpoints.up('sm')]: {
            margin: '-13px 6px -15px -11px',
            svg: { width: '42px', height: '42px' },
          },

          [theme.breakpoints.up('md')]: {
            margin: '-14px 9px -16px -10px',
            svg: { width: '44px', height: '44px' },
          },

          [theme.breakpoints.up('lg')]: {
            margin: '-16px 10px -18px -10px',
            svg: { width: '48px', height: '48px' },
          },
        },
      },

      '&.MuiButton-containedInverted': {
        color: theme.palette.primary.main,
      },

      [`&.${buttonClasses.contained}.${buttonClasses.disabled}`]: {
        color: theme.palette.background.default,
        backgroundColor: 'var(--color-medium-light-grey)',
      },

      [`&.${buttonClasses.outlined}.${buttonClasses.disabled}`]: {
        color: 'var(--color-medium-light-grey)',
        borderColor: 'var(--color-medium-light-grey)',
      },

      [`&.${buttonClasses.text}`]: {
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 'var(--spacer-xs)',
        transition: colorTransition('color'),

        '&::after': {
          content: '" "',
          width: '100%',
          height: '2px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          transition: colorTransition('background-color'),
          transformOrigin: 'left',
          transform: 'scaleX(1)', // prevent rendering bugs
          backgroundColor: theme.palette.primary.main,
        },

        [`&.${buttonClasses.colorSecondary}`]: {
          borderColor: theme.palette.secondary.main,

          '&::after': {
            backgroundColor: theme.palette.secondary.main,
          },
        },

        '&.MuiButton-textInverted::after': {
          backgroundColor: 'var(--color-white)',
        },

        [`&.${buttonClasses.disabled}::after`]: {
          color: 'var(--color-medium-light-grey)',
          backgroundColor: 'var(--color-medium-light-grey)',
        },

        '&:hover, &:focus, .link-container:hover &, .link-container:focus &': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          '&::after': {
            transform: 'scaleX(0.5)',
            backgroundColor: theme.palette.primary.main,
          },
        },

        '&.MuiButton-textInverted': {
          '&:hover, &:focus': {
            color: 'var(--color-white)',

            '&::after': {
              backgroundColor: 'var(--color-white)',
            },
          },
        },

        [`.${buttonClasses.startIcon}`]: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
});

export default button;
